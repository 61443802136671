import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../components/Layout'
import Spacing from '../../../components/partials/Spacing'
import gsap from 'gsap'
import Loadable from '@loadable/component'

const LoadableChapter3 = Loadable(
  () => import('../../../components/monitoringGuide/RestOfChapter3')
)

const chapter3 = ({ transitionStatus }) => {
  useEffect(() => {
    if (transitionStatus === 'exiting') {
      // Scrolling top to prevent flickering
      window.scrollTo(0, 0)
      //Taking care of self animation
      gsap.to('.Chapter3', { x: window.innerWidth, duration: 0.83 })
      // Taking care of other animations
      gsap.fromTo('.Intro', { x: -window.innerWidth }, { x: 0, duration: 0.83 })
      gsap.fromTo(
        '.Chapter1',
        { x: -window.innerWidth },
        { x: 0, duration: 0.83 }
      )
      gsap.fromTo(
        '.Chapter2',
        { x: -window.innerWidth },
        { x: 0, duration: 0.83 }
      )
    }
  }, [transitionStatus]) //execute when transitionStatus change
  const data = useStaticQuery(graphql`
    query Kafka_Guide_Chapter3 {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Kafka_Guide_Form_Page" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    // Set here to Prevent doubleScrollbar
    document.querySelector<HTMLElement>('.tl-edges').style.overflowX = 'hidden'
    // SetTimeout to defer execution
    setTimeout(() => {
      // Update style here to prevent flicker effect
      document.querySelector<HTMLElement>('.by-infra').style.color = '#ffcf42'
      document
        .querySelector<HTMLImageElement>('.img-1')
        .classList.remove('d-none')
    }, 100)
  }, [])

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout type="KafkaMonitoring" noFooter={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>

      <div>
        <section className="kafka-monitoring-intro-1">
          <Spacing />
          <div className="pt-5 mt-5 container-2 Chapter3 mh-100">
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-lg-6 col-12">
                <h2 className="by-infra kafka-monitoring-intro-1-text f-64 fw-900">
                  By component
                </h2>
              </div>
              <div className="col-lg-6 col-12 mobile-margin-top">
                <div className="d-sm-block d-lg-none">
                  <Spacing />
                </div>
                <div className="text-white">
                  <StaticImage
                    className="img-1 d-none img-fluid"
                    quality={100}
                    src="../../../resources/images/kafka_guide/chapter3/chapter3_hero.svg"
                    placeholder="tracedSVG"
                    alt="By Component"
                  />
                </div>
              </div>
            </div>
          </div>
          <Spacing />
        </section>

        <LoadableChapter3 />
      </div>
    </Layout>
  )
}

export default chapter3
